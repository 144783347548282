import React from 'react';
import { TableRow, TableCell, Skeleton } from '@mui/material'; 

export default function TableSkeleton({ rowsPerPage, TABLE_HEAD }) {
    return (
      <>
        {[...Array(rowsPerPage)].map((_, index) => (
          <TableRow key={index}>
            {TABLE_HEAD.map((column) => (
              <TableCell key={column.id} align={column.align}>
                <Skeleton animation="wave" height={24} width="80%" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </>
    );
  }