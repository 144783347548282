import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

RHFTextArea.propTypes = {
  name: PropTypes.string,
};

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
    background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50]};

    &:hover {
      border-color: ${theme.palette.grey[900]};
    }

    &:focus {
      border-color: ${theme.palette.grey[300]};
      box-shadow: 0 0 0 1px ${theme.palette.mode === 'dark' ? theme.palette.grey[100] : theme.palette.grey[100]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );



export default function RHFTextArea({ name, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Textarea {...field}  minRows={3} maxRows={40} error={!!error} helperText={error?.message} {...other} placeholder="Paste user list here" />
      )}
    />
  );
}
