import propTypes from 'prop-types'
import { createContext, useEffect, useReducer, useState } from 'react'
import useLocalStorage from '../hooks/useLocalStorage';
import axios from '../utils/axios';
import { ApiEndpoints } from '../config';

const initialState = {
    isOpen: false,
    isEdit: false,
    channelList: [],
    channelErrors: {},
    getChannels: () => {},
    getChannelTypes: () => {},
    updateChannel: () => {},
    toggleDrawer:() => {},
    openDrawer: () => {},
    selectedChannel: {
        is_edit:false
    },
    editChannel: () => {}
}

const ChannelContext= createContext(initialState)

ChannelContextProvider.propTypes = {
    children: propTypes.node
}

const handlers = {
    GETCHANNELS: (state, action) => {
        const channelList = action.payload
        return {
            ...state,
            channelList
        }
    },
    ERROR: (state, action) => {
        return {
            ...state,
            channelErrors: {
                ...action.payload
            }
        }
    },
    TOGGLE_DRAWER: (state, action) => {
        return {
            ...state,
            isOpen: action.payload.open,
            selectedChannel: {...state.selectedChannel, is_edit: action.payload.is_edit}
        }
    },
    EDIT_CHANNEL: (state, action) => {
        return {
            ...state,
            selectedChannel: {
                channel_id: action.payload.id,
                channel_type: action.payload.channel.id,
                name: action.payload.name,
                company_handle: action.payload.company_handle,
                is_edit: true
            }
        }
    },
    ADD_CHANNEL: (state, action) => {
        return {
            ...state,
            selectedChannel: {
                is_edit: false
            }
        }
    },
    CLEAR_CHANNEL:(state, action) => {
        return {
            ...state,
            selectedChannel: {
                channel_id: '',
                channel_type: '',
                name: '',
                company_handle: '',
                is_edit: false
            }
        }
    }


}

const reducer = (state, action) => ( handlers[action.type] ? handlers[action.type](state, action): state)

 function ChannelContextProvider ({children}) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const getChannels = async () => {
       await axios.get(ApiEndpoints.ALLCHANNELS).then(res => {
            try {
                const channels = res.data.data.department_channels
                dispatch({
                    type: 'GETCHANNELS',
                    payload: channels
                })

            } catch(err) {
                dispatch({
                    type: 'ERROR',
                    payload: {
                        errorCode:res.data.data.status,
                        errorMessage: res.data.data.message
                    }
                })
            }
        })
    }

    const getChannelTypes = async () => {
        await axios.get(ApiEndpoints.DEPARTMENTCHANNELS).then(res => {
            try {
                if(res.data.data.status === 200) {
                    dispatch({
                        type: 'SUCCESS',
                        payload: res.data.data.message
                    })
                }
            } catch(error) {
                dispatch({
                    type: 'ERROR',
                    payload: {
                        errorCode:res.data.data.status,
                        errorMessage: res.data.data.message
                    }
                })
            }
        })
        return channels
    }

    const updateChannel = async (data, id) => {
        await axios.post(ApiEndpoints.CREATE_CHANNEL+'/'+id,data).then(res => {
            try {
                if(res.data.data.status === 200) {
                    dispatch({
                        type: 'SUCCESS',
                        payload: res.data.data.message
                    })
                }
            } catch(error) {
                dispatch({
                    type: 'ERROR',
                    payload: {
                        errorCode:res.data.data.status,
                        errorMessage: res.data.data.message
                    }
                })
            }
        });
        
    };

    const toggleDrawer = (open) => {
        dispatch({
            type: 'TOGGLE_DRAWER',
            payload: open
        })
    }

    const editChannel = (data) => {
        dispatch({
            type: 'EDIT_CHANNEL',
            payload: data
        })
    }

    const addNewChannel = () => {
        dispatch({
            type: 'ADD_CHANNEL',
            payload: false
        })
    }

    const clearChannel = () => {
        dispatch({
            type: 'CLEAR_CHANNEL',
            payload: {}
        })
    }


    return <ChannelContext.Provider
        value={{
            ...state,
            getChannels,
            getChannelTypes,
            updateChannel,
            editChannel,
            addNewChannel,
            clearChannel,
            toggleDrawer
        }}
    >
        {children}
    </ChannelContext.Provider>
}
export {ChannelContext, ChannelContextProvider}