import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};
function NavSectionHorizontal({ navConfig }) {
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userRole = userData?.user?.user_type;
  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig
          .filter((item) => (userRole === 'AGENT' || userRole === 'AUDITOR' ? item.subheader !== 'general' : item))
          .map((group) => (
            <Stack key={group.subheader} direction="row" flexShrink={0}>
              {group.items
                .filter((item) =>
                  userRole === 'ADMIN'
                    ? item.title !== 'Organization' || item.title !== 'Chat'
                    : userRole === 'AGENT'
                    ? item.title === 'Chat'
                    : userRole === 'AUDITOR'
                    ? item.title === 'Agents' || item.title === 'Chat' || item.title === 'Auditors'
                    : userRole === 'SUPER_ADMIN' ? item.title !== 'Chat' :  item
                )
                .map((list) => (
                  <NavListRoot key={list.title} list={list} />
                ))}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
