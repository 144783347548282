import React from "react";
import { FormControl, FormHelperText, InputLabel, InputBase } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import './PhoneNumberInputStyles.css'; 

export const PhoneNumberInput = ({ field, form, label, helpText, error,handleTextFieldChange,button, item, ...props }) => {
  const { name, value, onChange } = field;
  const { touched, errors } = form;

  const isError = Boolean(touched[name] && errors[name]);

  return (
    <FormControl fullWidth error={isError} variant="outlined">
      {/* {label && <InputLabel shrink>{label}</InputLabel>} */}
      <PhoneInput
        className="mui-phone-input"
         placeholder="Enter mobile number"
        name={name}
        value={value ? (value.startsWith("+") ? value : `+${value}`) : value}
        onChange={(v) => {
          form.setFieldValue(name, v ? (v.startsWith("+") ? v.slice(1) : v) : v);
         handleTextFieldChange(button.option, item.id, 'phone_number', v)
        }}
        {...props}
      />
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
      {isError && <FormHelperText error>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default PhoneNumberInput;
