import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_APP } from '../routes/paths';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userRole = userData?.user?.user_type;
  const path =
    userData &&
    (userRole === 'ADMIN'
      ? PATH_APP.general.dashboard
      : userRole === 'SUPER_ADMIN'
      ? PATH_APP.general.dashboard
      : userRole === 'AUDITOR'
      ? PATH_APP.management.agents
      : PATH_APP.management.chat);
  if (isAuthenticated && path) {
    return <Navigate to={path} />;
  }

  return <>{children}</>;
}
