import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

RHFTextField.propTypes = {
  name: PropTypes.string,
  inputProps: PropTypes.object,
  pattern: PropTypes.string,
  autoComplete: PropTypes.string,
};

export default function RHFTextField({ name, type, autoComplete = 'off', inputProps, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type={type}
          {...field}
          fullWidth
          error={!!error}
          helperText={error?.message}
          autoComplete={autoComplete}
          InputProps={{ ...inputProps, autoComplete: 'new-password' }}
          {...other}
        />
      )}
    />
  );
}