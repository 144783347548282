import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

RHFCustomDatePicker.propTypes = {
  name: PropTypes.string,
  dateFormat: PropTypes.string,
};

export default function RHFCustomDatePicker({ name, label, dateFormat, minDate, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            {...field}
            label={label}
            minDateTime={minDate}
            inputFormat={dateFormat}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error?.message}
                fullWidth
              />
            )}
            {...other}
          />
        </LocalizationProvider>
      )}
    />
  );
}