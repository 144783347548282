// routes
import { PATH_APP } from './routes/paths';

export const DATE_FORMATS = {
  dateTime: "yyyy-MM-dd HH:mm"
}

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';


export const MAPBOX_API = process.env.REACT_APP_MAPBOX;


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_APP.general.dashboard; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 64 - 10,
};

export const NAVBAR = {
  BASE_WIDTH: 160,
  DASHBOARD_WIDTH: 200,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 35,
  DASHBOARD_ITEM_SUB_HEIGHT: 15,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 25,
};

export const ICON = {
  NAVBAR_ITEM: 17,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const ApiEndpoints = {
  LOGIN: 'login',
  SIGNUP: 'register/user',
  DEPARTMENTCHANNELS: 'channels/get',
  ALLCHANNELS: 'channels',
  DEPARTMENTS: 'organization-department',   
  TEMPLATES: 'templates',
  NEWTEMPLATEDROPDOWN: 'templates/dropdown',
  NEWTEMPLATE: 'templates/new',
  UPDATETEMPLATE: 'templates/update',
  DELETETEMPLATE: 'templates/delete',
  ADDDEPARTMENT: 'departments/new',
  UPDATEDEPARTMENT: 'departments/update',
  DELETEDEPARTMENT: 'departments/delete',
  ALL_AGENTS: 'agents',
  GETAGENT: 'agents',
  ADDAGENT: 'agents/new',
  UPDATEAGENT: 'agents/update',
  DELETEAGENT: 'agents/delete',
  FAILEDMESSAGES: 'analytics/failed',
  GETAUDITORS: 'auditors',
  ADDAUDITOR: 'auditors/new',
  UPDATEAUDITOR: 'auditors/update',
  DELETEAUDITOR: 'auditors/delete',
  CREATE_CHANNEL: 'channels/new',
  ALL_DEPARTMENTS: 'departments',
  DELETE_CHANNEL: 'channels/delete',
  UPDATE_CHANNEL: 'channels/update/',
  GETTEMPLATES: 'templates',
  CAMPAIGNS: 'campaigns',
  ADDCAMPAIGN: 'campaigns/create',
  UPDATECAMPAIGN: 'campaigns/update',
  DELETECAMPAIGN: 'campaigns/delete',
  UPLOAD_FILES: '/files/upload',
  WEBHOOKS: 'webhook',
  NEWWEBHOOK: 'webhook/new',
  UPDATEWEBHOOK: 'webhook/update',
  DELETEWEBHOOK: 'webhook/delete',
  CREATEAPI: '/api-generator/new',
  UPDATEAPI: '/api-generator/update',
  CREATEIFRAME: '/iframe/new',
  UPDATEIFRAME: '/iframe/update',
  ORGANIZATION: '/admin',
  UNSUBSCRIPTION_RULES_LIST: '/unsubscriptionrules',
  UNSUBSCRIPTION_RULES_LIST_NEW:'/unsubscriptionrules/new',
  UNSUBSCRIPTION_RULES_LIST_UPDATE:'/unsubscriptionrules/update',
  DELETE_UNSUBSCRIPTON_RULES: '/unsubscriptionrules/delete',
  AUTO_REPLY_RULES_LIST: '/autoreply',
  AUTO_REPLY_NEW: 'autoreply/new',
  AUTO_REPLY_UPDATE: '/autoreply/update',
  DELETE_AUTO_REPLY: 'autoreply/delete',
  MESSAGE_ROUTING_CONFIGURATION_LIST: '/messageroutingrule',
  MESSAGE_ROUTING_CONFIGURATION_NEW: '/messageroutingrule/new',
  MESSAGE_ROUTING_CONFIGURATION_UPDATE:'/messageroutingrule/update',
  DELETE_MESSAGE_ROUTING_CONFIGURATION:'/messageroutingrule/delete',
  DATA_EXTRACTION_RULES_LIST: '/dataextraction',
  DATA_EXTRACTION_RULES_NEW: '/dataextraction/new',
  DATA_EXTRACTION_RULES_UPDATE: '/dataextraction/update',
  DELETE_DATA_EXTRACTION_RULES: '/dataextraction/delete',
  CHAT: 'chat',
  GET_ALLCHAT_HISTORY:'/bot/getChatHistory/',
  GET_ALL_CHAT_HISTORY_BY_CONVERSATION_ID: '/bot/getAllChatHistory/',
  GET_ALL_CHAT_CONTACTS : '/bot/customers/unresolved/',
  GET_ALL_CHAT_UNRESOLVED :'/bot/customers/resolved/',
  GET_CHAT_MESSAGES: '/bot/chatMessages/',
  CLOSE_CHAT_BY_AGENT: '/bot/close',
  CRM_USER_LIST :'/customer',
  CRM_CREATE_USER :'/customer/create',
  CRM_UPDATE_USER :'/customer/update',
  CRM_DELETE_USER :'/customer/delete',
  CRM_GROUP_LIST :'/customer-group',
  CRM_DELETE_GROUP : '/customer-group/delete',
  CRM_CREATE_GROUP:'/customer-group/create',
  CRM_UPDATE_GROUP:'/customer-group/update',
  CUSTOMER_FILTERS:'/customer/filters',
  GROUP_FILTERS:'/customer-group/filters',
  CRM_SEGMENT_LIST:'/customer-segment',
  CRM_CREATE_SEGMENT:'/customer-segment/create',
  CRM_DELETE_SEGMENT:'/customer-segment/delete',
  CRM_UPDATE_SEGMENT:'/customer-segment/update',
  WA_TEMPLATES_MASTER_CATEGORY : '/templates/whatsapp/master/category',
  CHAT_CUSTOMER_UPDATE:'/customer/modify',
  WA_TEMPLATES_LIBRARY : '/templates/whatsapp/library',
  WA_TEMPLATE_CREATE: '/templates/whatsapp',
  WA_TEMPLATE_MEDIA_UPLOAD : '/templates/whatsapp/media/upload',
  AGENT_DOC_UPLOAD : '/files/upload',
  ANALYTICS_BOT:'/analytics/bot',
  ANALYTICS_TOTAL:'/analytics/total'
}
