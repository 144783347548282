import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Divider } from '@mui/material';
//
import { NavListRoot } from './NavList';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: 'theme.palette.text.primary',
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, navCRMConfig, isCollapse = false, ...other }) {
  const userData = useSelector((state) => state?.auth?.user?.data);
  const userRole = userData?.user?.user_type;
  return (
    <Box {...other}>
      {userRole && navConfig ?
        navConfig
          .filter((item) => (userRole === 'AGENT' || userRole === 'AUDITOR' ? item.subheader !== 'general' : item))
          .map((group) => (
            <>
              <List key={group.subheader} disablePadding sx={{ px: 1 }}>
                {!isCollapse &&<ListSubheaderStyle
                  sx={{
                    ...(isCollapse && {
                      opacity: 0,
                    }),
                  }}
                >
                  {group.subheader}
                </ListSubheaderStyle>}

                {group.items
                  .filter((item) =>
                    userRole === 'ADMIN'
                      ? (item.title !== 'Organization' && item.title !== 'Chat')
                      : userRole === 'AGENT'
                        ? item.title === 'Chat'
                        : userRole === 'AUDITOR'
                          ? item.title === 'Agents' || item.title === 'Chat'
                        : userRole === 'SUPER_ADMIN' ? item.title !== 'Chat' : item
                )
                  .map((list) => (
                    <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                  ))}
              </List>
              <Divider />
            </>
          )) : navCRMConfig
            .map((group) => (
              <>
              <List key={group.subheader} disablePadding sx={{ px: 1 }}>
                {!isCollapse &&<ListSubheaderStyle
                  sx={{
                    ...(isCollapse && {
                      opacity: 0,
                    }),
                  }}
                >
                  {group.subheader}
                </ListSubheaderStyle>}
                {group.items.map((list) => (
                  <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                ))}
              </List>


              {/* <List key={group.items.title} disablePadding sx={{ px: 1 }}>
                {group.items.map((list) => (
                  <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                ))}
            </List> */}
            </>
          ))}

    </Box>
  );
}
