import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField, Chip } from '@mui/material';

RHFAutoComplete.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  multiple:PropTypes.bool
};
function RHFAutoComplete({ name, label, options, defaultValue,multiple, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || []}
      render={({ field,  fieldState: { error } }) => {
        const filteredOptions = multiple ? options.filter(option => !field.value.find(selectedOption => selectedOption.value === option.value)): options;
        return <Autocomplete
        {...field}
          options={filteredOptions}
          getOptionLabel={(option) => option.label}
          onChange={(e, newValue) => {
            const selectedValue = newValue ? newValue.value : '';
              field.onChange(selectedValue);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip {...getTagProps({ index })} key={option.value} size="small" label={option.label} />)
          }
          value={options.find(option => option.value === field.value) || null} 
          defaultValue={''}
          getOptionSelected={(option, value) => option.value === value.value}
          renderInput={(params) => <TextField error={!!error}  helperText={error?.message} label={label} {...params} />}
          {...other}
        />
      }}
    />
  );
}

export default RHFAutoComplete;

